@import './styles/actionBar';

.stripe-background {
  background-image: repeating-linear-gradient(45deg, #ffffff, #ffffff 10px, #f3f4f6 10px, #f3f4f6 20px)!important;
  opacity:1!important;
}
.fc-non-business {
  background-image: repeating-linear-gradient(45deg, #ffffff, #ffffff 10px, #f3f4f6 10px, #f3f4f6 20px);
}


table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
}
.booked {
  background-color: #f88;
}
.available {
  background-color: #8f8;
}
.fc-toolbar-chunk {
  display: flex;
  justify-content: space-between;
}